/** @jsx jsx */
import { jsx, Heading, Container } from "theme-ui";

import React, { Component } from "react";
import SEO from "../components/SEO/SEO";

import Helmet from "react-helmet";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import Layout from "../layout";
import config from "../../data/SiteConfig";

class ProjPage extends Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div>
            <Heading variant="styles.h1" sx={{ color: "primary" }}>
              {post.title}
            </Heading>

            <Container variant="styles">
              <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            </Container>
      
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProjPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ProjBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      rawMarkdownBody
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
